const cityList = [
  "Mumbai",
  "Delhi-NCR",
  "Kolkata",
  "Chennai",
  "Bengaluru",
  "Hyderabad",
  "Pune",
  "Ahmedabad",
  "Jaipur",
  "Lucknow",
  "Chandigarh",
  "Bhopal",
  "Indore",
  "Kochi",
  "Thiruvananthapuram",
  "Coimbatore",
  "Vadodara",
  "Nagpur",
  "Agra",
  "Amritsar",
  "Surat",
  "Kanpur",
  "Ludhiana",
  "Varanasi",
  "Allahabad",
  "Visakhapatnam",
  "Patna",
  "Bhubaneswar",
  "Ranchi",
  "Guwahati",
  "Jammu",
  "Srinagar",
  "Shimla",
  "Dehradun",
  "Raipur",
  "Goa",
  "Thane",
  "Navi Mumbai",
  "Rajkot",
  "Meerut",
  "Faridabad",
  "Pimpri-Chinchwad",
  "Dhanbad",
  "Howrah",
  "Jalandhar",
  "Gwalior",
  "Vijayawada",
  "Madurai",
  "Jabalpur",
  "Kota",
  "Aurangabad",
  "Salem",
  "Aligarh",
  "Bhiwandi",
  "Saharanpur",
  "Gorakhpur",
  "Bhilai",
  "Amravati",
  "Noida",
  "Jamshedpur",
  "Bhavnagar",
  "Warangal",
  "Cuttack",
  "Firozabad",
  "Kozhikode",
  "Bhilwara",
  "Guntur",
  "Bikaner",
  "Amravati",
  "Nellore",
  "Ajmer",
  "Rajpur Sonarpur",
  "Tumkur",
  "Ujjain",
  "Malegaon",
  "Jamnagar",
  "Mangalore",
  "Erode",
  "Belgaum",
  "Ambattur",
  "Tirunelveli",
  "Malegaon",
  "Gaya",
  "Jalgaon",
  "Udaipur",
  "Maheshtala",
  "Tirupur",
  "Davanagere",
  "Kurnool",
  "Rajahmundry",
  "Bokaro",
  "South Dumdum",
  "Bellary",
  "Patiala",
  "Gopalpur",
  "Agartala",
  "Bhagalpur",
  "Muzaffarnagar",
  "Bhatpara",
  "Panihati",
  "Latur",
  "Dhule",
  "Tirupati",
  "Rohtak",
  "Korba",
  "Bhilai Nagar",
  "Brahmapur",
  "Muzaffarpur",
  "Ahmednagar",
  "Mathura",
  "Kollam",
  "Avadi",
  "Rajpur Sonarpur",
  "Kadapa",
  "Kamarhati",
  "Bilaspur",
  "Shahjahanpur",
  "Bijapur",
  "Godhra"
    
  ];
  
  export default cityList;
  